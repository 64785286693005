import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';


import CommonLayout from '../../components/common/CommonLayout';
import { setActiveLink } from '../../store/Link/action';
import { useDispatch } from 'react-redux';
import Loader from '../../components/common/Loader';
import { setQuizFirst, setQuizSecond, setQuizThird } from '../../store/Quiz/action';
import { setO_photo, setUserName } from '../../store/Role/action';
import { useSelector } from 'react-redux';
import { handleDynamicRequestHeader } from '../../components/common/DyanamicRequest';
import { BASE_URL } from '../../components/common/AppConfig';


import stars from '../../assets/images/starts.png';
import testone from '../../assets/images/testone.png';
import testtwo from '../../assets/images/testtwo.png';
import testthree from '../../assets/images/testthree.png';
import reports from '../../assets/images/reports.png';
import profile_bugs from '../../assets/images/profile-bugs.png';
import SpacebugsImg from '../../assets/images/spacebugs-img-box.svg';
import profile_activity from '../../assets/images/profile-activiti.png';
import SpacebugPopup from '../../assets/images/spacebug-popup.svg';
import edit_form from '../../assets/images/edit-form.png';

import SetAlert from '../../components/common/SetAlert';
import { InfoCircleFill } from 'react-bootstrap-icons';

function SpaceBucks() {
    const [isLoader, setIsLoader] = useState(true);
    const [spaceBucks, setSpaceBucks] = useState([]);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const userToken = useSelector((state) => state.roleReducer.jwtToken);
    const [isOpen, setIsOpen] = useState(false);


    const openModel = () => {
        setIsOpen(true);
    };

    const closeModel = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        fetchSpaceBucksData();
        setTimeout(() => {
            setIsLoader(false);
        }, 2000);
    }, []);


    const fetchSpaceBucksData = async () => {

        const requestBody = {};
        const method = 'POST';
        const url = `${BASE_URL}/mobile/spacebucksDetails`;
        const token = await userToken;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
            setSpaceBucks(data);

        };
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse);

    }
    console.log(spaceBucks)



    const test1Title = { title: 'Complete first test to get 350 SpaceBucks', image: testone, bucks: 350 };
    const test2Title = { title: 'Complete second test to get 350 SpaceBucks', image: testtwo, bucks: 350 };
    const test3Title = { title: 'Complete third test to get 300 SpaceBucks', image: testthree, bucks: 300 };
    const downloadReportTitle = { title: 'Download report to get 50 SpaceBucks', image: reports, bucks: 50 };
    const webinarInterestTitle = {
        title: 'Explore your career and attend the webinar and get 200 SpaceBucks per webinar',
        note: 'Note:- Total 400 SpaceBucks will get deducted from you account per career exploration, 200 SpaceBucks for showing interest and other 200 for registration in webinar.', image: stars, bucks: 200
    };
    const studyAbroadTitle = {
        title: 'Schedule a one-to-one call to counsellor to get suggestions for studying abroad.',
        note: 'Note:- For scheduling, 400 SpaceBucks will get deducted from your account.', image: stars, bucks: 400
    };


    return (
        <>
            {isLoader ? (

                <Loader />
            ) : (
                <CommonLayout>
                    <div className="left-navigation">
                        <Sidebar></Sidebar>
                    </div>
                    <div className="right-content">
                        <Navbar></Navbar>
                        <section className="profile-tab-nav">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-past-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-past" type="button" role="tab" aria-controls="nav-past"
                                        aria-selected="true">Completed</button>
                                    <button className="nav-link" id="nav-personal-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-personal" type="button" role="tab" aria-controls="nav-personal"
                                        aria-selected="false">Earn SpaceBucks</button>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="profile-past-activities completed-spacebugs-tab tab-pane fade show active" id="nav-past" role="tabpanel" aria-labelledby="nav-past-tab">

                                    <div className='row'>
                                        <div className='col-lg-7'>
                                            <h2>Completed</h2>
                                            <div className="row">
                                                {spaceBucks?.test1 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={test1Title.image} alt="" />
                                                            <div>
                                                                <h3>{test1Title.title}</h3>
                                                                <p></p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{test1Title.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}


                                                {spaceBucks?.test2 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={test2Title.image} alt="" />
                                                            <div>
                                                                <h3>{test2Title.title}</h3>
                                                                <p></p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{test2Title.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}


                                                {spaceBucks?.test3 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={test3Title.image} alt="" />
                                                            <div>
                                                                <h3>{test3Title.title}</h3>
                                                                <p></p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{test3Title.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}

                                                {spaceBucks?.stage2 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={downloadReportTitle.image} alt="" />
                                                            <div>
                                                                <h3>{downloadReportTitle.title}</h3>
                                                                <p></p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{downloadReportTitle.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}


                                                {spaceBucks?.stage3 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={webinarInterestTitle.image} alt="" />
                                                            <div>
                                                                <h3>{webinarInterestTitle.title}</h3>
                                                                <p>{webinarInterestTitle.note}</p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{webinarInterestTitle.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}
                                                {spaceBucks?.stage3 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={studyAbroadTitle.image} alt="" />
                                                            <div>
                                                                <h3>{studyAbroadTitle.title}</h3>
                                                                <p>{studyAbroadTitle.note}</p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{studyAbroadTitle.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                        <div className='col-lg-5'>
                                            <div className='Spacebugs-right-img'>
                                                <span>2,43,9002</span>
                                                <img src={SpacebugsImg} alt="" />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="tab-pane fade profile-past-activities show " id="nav-personal" role="tabpanel" aria-labelledby="nav-personal-tab">
                                    <div className='row'>
                                        <div className='col-lg-7'>
                                            <h2>Earn SpaceBucks</h2>
                                            <div className="row">

                                                {!spaceBucks?.test1 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={test1Title.image} alt="" />
                                                            <div>
                                                                <h3>{test1Title.title}</h3>
                                                                <p></p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{test1Title.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}


                                                {!spaceBucks?.test2 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={test2Title.image} alt="" />
                                                            <div>
                                                                <h3>{test2Title.title}</h3>
                                                                <p></p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{test2Title.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}


                                                {!spaceBucks?.test3 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={test3Title.image} alt="" />
                                                            <div>
                                                                <h3>{test3Title.title}</h3>
                                                                <p></p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{test3Title.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}

                                                {!spaceBucks?.stage2 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={downloadReportTitle.image} alt="" />
                                                            <div>
                                                                <h3>{downloadReportTitle.title}</h3>
                                                                <p></p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{downloadReportTitle.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}


                                                {!spaceBucks?.stage3 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={webinarInterestTitle.image} alt="" />
                                                            <div>
                                                                <h3>{webinarInterestTitle.title}</h3>
                                                                <p>{webinarInterestTitle.note}</p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{webinarInterestTitle.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                )}
                                                {!spaceBucks?.stage3 > 0 && (
                                                    <div className="col-lg-12" >
                                                        <div className="past-activit-one spacebugs-img">
                                                            <img className='box-img' src={studyAbroadTitle.image} alt="" />
                                                            <div>
                                                                <h3>{studyAbroadTitle.title}</h3>
                                                                <p>{studyAbroadTitle.note}</p>
                                                                <span>
                                                                    <div className="profile-bugs">
                                                                        <img src={profile_bugs} alt="" />{studyAbroadTitle.bucks}
                                                                    </div>{" "}
                                                                    <strong>Space</strong>bucks earned
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                        <div className='col-lg-5'>
                                            <div className='Spacebugs-right-img'>
                                                <span>2,43,9002</span>
                                                <img src={SpacebugsImg} alt="" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        {/* Popup */}
               
                     


                        {/* Popup */}
                    </div>
                </CommonLayout>

            )}
        </>
    )
}

export default SpaceBucks
