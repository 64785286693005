import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { publicRoutes, authProtectedRoutes,authStudentRoutes, authConsolerRoutes } from "./routes";
import LoginAuthentication from "./pages/Authentication/Login";
import "./assets/scss/app.scss";
import { useSelector } from 'react-redux';

function App() {
  const Login = LoginAuthentication;

    const sessionToken = sessionStorage.getItem('sessionToken'); 
  const userRole = useSelector((state) => state.roleReducer.userRole);

  return (
    <Router>
      <Routes>
        {/* Rendering public routes only if there's no active session */}
        {!sessionToken &&
          publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<route.component />}
            />
          ))
        }

        {authProtectedRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              sessionToken ? <route.component /> : <Navigate to="/" />
            }
          />
        ))}
        {!sessionToken && <Route path="/*" element={<Navigate to="/" />} />}
        {sessionToken && (userRole.includes('ROLE_PARENT') || userRole.includes('NEW_ACCOUNT')) && authProtectedRoutes.some(route => route.path === "/profile") && (
  <Route path="/*" element={<Navigate to="/profile" />} />
)}
{sessionToken && userRole.includes('ROLE_STUDENT') && authStudentRoutes.some(route => route.path === "/dashboard") && (
  <Route path="/*" element={<Navigate to="/dashboard" />} />
)}
{sessionToken &&  userRole.includes('ROLE_COUNSELLOR') && authConsolerRoutes.some(route => route.path === "/consoler") && (
  <Route path="/*" element={<Navigate to="/consoler" />} />
)}


      </Routes>
    </Router>
  );
}

export default App;
