import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Star from '../../assets/images/star.svg';

import Malvika from '../../assets/images/student.jpeg';
import Logout from '../../assets/images/logout.png';
import Profile_icon from '../../assets/images/profile-icon.png';

import Arrow_Left from '../../assets/images/arrow-left.png';
import { useDispatch } from 'react-redux';
import { setQuizFirst, setQuizSecond, setQuizThird } from '../../store/Quiz/action';
import { setJwtToken, setUserRole, setUserId,setLogout, setSpaceBucksNew } from '../../store/Role/action';
import { setActiveLink, setLogoutLink } from '../../store/Link/action';
import { setLogoutCareer } from '../../store/Career/action';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleDynamicRequestHeader } from '../common/DyanamicRequest';
import { BASE_URL } from '../common/AppConfig';
import Notification from '../Notifications/Notification';
import { setDisplayOff } from '../../store/Link/action';

function Navbar({ handleBackClick, selectedStudent, }) {
   const [showUserPopup, setShowUserPopup] = useState(false);
    //const [spaceBucks, setSpaceBucks] = useState('');
    const activeLink = useSelector((state) => state.linkReducer.activeLink);
    const location = useLocation();
    const path = location.pathname.replace(/^\/+/, ''); 
    const showBackButton = path.includes('activity/webinar') || path.includes('courses');
    const o_photo = useSelector((state) => state.roleReducer.o_photo);
    const userName = useSelector((state) => state.roleReducer.userName);
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const userToken = useSelector((state) => state.roleReducer.jwtToken);
    const spaceBucks = useSelector((state) => state.roleReducer.spaceBucks);
    const [showNotification, setShowNotification] = useState(false);
   
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClose=()=>{
        dispatch(setDisplayOff(true));
        toggleUserPopup();
    }
  

    const toggleUserPopup = () => {
        setShowUserPopup(!showUserPopup);
        setShowNotification(false);
        if(!showUserPopup){
            dispatch(setDisplayOff(false));
        }
    };

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.clear();
        setTimeout(() => {
            navigate('/');
            window.location.reload();
        }, 10);

        dispatch(setQuizFirst(false));
        dispatch(setQuizSecond(false));
        dispatch(setQuizThird(false));
        dispatch(setLogout());
        dispatch(setLogoutLink());
        dispatch(setLogoutCareer());
    }
    const handleProfile = () => {
           setTimeout(() => {
            navigate('/user');
            
        }, 10);
    }

    const handleSpacebucks = async () => {
        
        const requestBody = { };
        const method = 'POST';
        const url = `${BASE_URL}/mobile/getSpacebucks`;
        const token = userToken;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
         // setSpaceBucks(data.amount);
         dispatch(setSpaceBucksNew(data.amount));
        }
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse);
   
}

useEffect(()=>{
    if(!userRole.includes('ROLE_COUNSELLOR')){
        handleSpacebucks();
    }
    
},[]);

    return (
        <div className="main-nav-top">
            {!showBackButton &&
                <div className="welcome-user">
                    <h2>Hi {userName},</h2>
                    <span>Welcome to AcadSpace!</span>
                </div>
            }
            {showBackButton &&
                <div className="welcome-user d-flex justifu-content-center align clickable" onClick={handleBackClick}>
                    <img className='clickable' src={Arrow_Left} alt="stars" />
                    <h2> &nbsp; Back</h2>
                </div>
            }
            <div className="user-all-details">
     { !userRole.includes('ROLE_COUNSELLOR') &&
                   <div className="star-points">
                   <span>
                       <img src={Star} alt="stars" />
                       <div className="total-count">{spaceBucks}</div>
                   </span>
               </div>
     }
                <div className="notification">
                   
                       
                        <Notification setShowUserPopup={setShowUserPopup} setShowNotification={setShowNotification} showNotification={showNotification}/>
                  
                </div>
                <div className="user-details" >

                    {o_photo ? (
                        <a href="#" onClick={toggleUserPopup}>
                            <img src={o_photo} alt="user-icon" />
                        </a>
                    ) : (
                        <a href="#" onClick={toggleUserPopup}>
                        <img src={Malvika} alt="Malvika" />
                        </a>
                    )}

                    {showUserPopup && (
                        <div className="popup-user" style={{ display: showUserPopup ? 'block' : 'none' }}>
                            <ul>
                                <button className='handle-close' onClick={handleClose}></button>
                                <li>
                                    <a onClick={handleProfile} className="profile-icon clickable">
                                        <img src={Profile_icon} alt="profile-icon" />
                                        Profile Details
                                    </a>
                                </li>
                                <li>
                                    <a onClick={handleLogout} className="logout clickable">
                                        <img src={Logout} alt="logout" /> Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>


    )
}

export default Navbar
