import React from 'react'

function ForgetPassword() {
  return (
    <div>
      pass
    </div>
  )
}

export default ForgetPassword
